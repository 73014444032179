<template>
    <div class="card card-custom example example-compact">
        <div class="card-header">
            <h3 class="card-title">{{title}}</h3>
            <button type="button" class="close" @click="cancelForm()">
                <i aria-hidden="true" class="ki ki-close"></i>
            </button>
        </div>

        <!--begin::Form-->
        <form class="form fv-plugins-bootstrap fv-plugins-framework" ref="dataForm" id="dataForm">
            <div class="card-body" style="min-height:500px;max-height:500px;overflow:auto;">

                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">仓库名称 <span class="text-danger">*</span>:</label>
                    <div class="col-lg-6">
                        <b-form-select class="form-control" v-model="data_form.warehouse_id" :options="warehouse_list"  name="warehouse_id">

                        </b-form-select>
                    </div>
                </div>

                <b-tabs v-model="data_form.type">
                    <b-tab title="整仓盘点">
                        <b-card-text>
                            <div class="col-form-label" style="margin-top:10px;">
                                整仓盘点将盘点所有商品共「 {{product_count}} 」个, 所有SKU共 「 {{sku_count}} 」个
                            </div>
                        </b-card-text>
                    </b-tab>
                    <b-tab title="按货架盘点">
                        <b-card-text>
                            <div style="margin-top:10px;">
                                <treeselect
                                        :options="shelf_list"
                                        :multiple="true"
                                        placeholder="请选择货架"
                                        v-model="data_form.shelfs"
                                />
                            </div>
                        </b-card-text>
                    </b-tab>
                    <b-tab title="按产品盘点">
                        <b-card-text>
                            <div style="margin-top:10px;">
                                <treeselect
                                        :load-options="loadOptions"
                                        :default-expand-level="1"
                                        :async="true"
                                        :multiple="true"
                                        placeholder="请选择产品"
                                        v-model="data_form.products"
                                />
                            </div>
                        </b-card-text>
                    </b-tab>
                </b-tabs>


            </div>

            <div class="card-footer">
                <button ref="data_form_submit" class="btn btn-primary mr-2">保存</button>
                <button class="btn btn-secondary" type="reset" @click="cancelForm()">关闭</button>
            </div>
        </form>
        <!--end::Form-->
    </div>
</template>
<script>

    import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
    import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
    import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
    import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
    import KTUtil from "@/assets/js/components/util";
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import Treeselect from '@riophae/vue-treeselect'

    import {getProduct} from '@/api/stock';
    import {getWareHouse, getShelf} from '@/api/warehouse';
    import {getCheckCount, addCheck} from '@/api/checkwarehouse';

    export default {
        name: "stock_check_add",
        props: {
            title: String,
            dialog_data: Object,

        },
        created:function() {
            var _this = this;
            getWareHouse().then(function(res) {
                if(res && res.response){
                    _this.warehouse_list = KTUtil.listToSelectOptions(res.response, 'id', 'name');
                }
            });
        },
        components :{
            Treeselect
        },
        watch: {
            "data_form.type": function(val){
                var _this = this;
                if(_this.data_form.type == 0){
                    _this.loadCheckCount();
                } else if(_this.data_form.type == 1) {
                    if(_this.data_form.warehouse_id){
                        getShelf({'warehouse_id': _this.data_form.warehouse_id, 'status': -1}).then(function(res) {
                            if(res && res.response) {
                                var options = [];
                                res.response.forEach(function(val){
                                    options.push({'id': val.id, 'label': val.shelf_number});
                                });
                                _this.shelf_list = options;
                            }
                        });
                    }
                } else if(_this.data_form.type == 2) {

                }
            },
            "data_form.warehouse_id": function(val){
                var _this = this;
                _this.$set(_this.data_form, 'type', 0);
                _this.loadCheckCount();
            }
        },
        data() {
            return {
                data_form:{
                    warehouse_id: '',
                    type: 0,
                    shelfs: null,
                    products: null
                },
                warehouse_list: [],
                product_list: [],
                shelf_list: [],

                product_count: 0,
                sku_count: 0
            }
        },
        mounted() {
            const data_form = KTUtil.getById("dataForm");

            //表单数据验证
            this.fv = formValidation(data_form, {
                fields: {
                    warehouse_id: {
                        validators: {
                            notEmpty: {
                                message: "请选择仓库"
                            }
                        }
                    },
                },
                plugins: {
                    trigger: new Trigger(),
                    submitButton: new SubmitButton(),
                    bootstrap: new Bootstrap()
                }
            });
            this.fv.on("core.form.valid", () => {
                var _this = this;

                //按钮加载动画
                const submitButton = this.$refs["data_form_submit"];
                submitButton.classList.add("spinner", "spinner-light", "spinner-right");

                addCheck(_this.data_form).then(function (res){
                    if (res) {
                        KTUtil.toast(_this, '结果提醒', '保存成功', 'success');
                        data_form.reset();
                        _this.$emit('opeareResultHandler', 1);
                    }
                    submitButton.classList.remove(
                        "spinner",
                        "spinner-light",
                        "spinner-right"
                    );
                });
            });
        },
        methods: {
            cancelForm(){
                this.$emit('opeareResultHandler', 0);

            },
            regionChange (data) {
                this.data_form.struct_address = data;
            },
            onFloatInput(e){
                e.target.value = (e.target.value.match(/^\d*(\.?\d{0,2})/g)[0]) || null;
            },
            onIntInput(e){
                e.target.value = parseInt(e.target.value) || null;
            },
            loadOptions({ action, searchQuery, callback }) {
                var _this = this;
                if (action === 'ASYNC_SEARCH') {
                    if(_this.data_form.warehouse_id) {
                        getProduct({'product_title': searchQuery, 'warehouse_id': _this.data_form.warehouse_id}).then(function (res) {
                            if (res && res.response) {
                                var options = [];
                                res.response.forEach(function (value) {
                                    options.push({
                                        'id': value.id,
                                        'label': value.site_name + '  ' + value.product_title
                                    });
                                });
                                callback(null, options);
                            }
                        });
                    }
                }

            },
            loadCheckCount() {
                var _this = this;
                if(_this.data_form.warehouse_id) {
                    getCheckCount({'warehouse_id': _this.data_form.warehouse_id}).then(function (res) {
                        if (res && res.response) {
                            _this.product_count = res.response.product_count;
                            _this.sku_count = res.response.sku_count;
                        }
                    });
                }
            }
        }
    }
</script>
