import request from "./request";

export function getProduct(params){
    return request({
        url: 'stock/getProduct',
        method: 'post',
        data: params
    });
}

export function getProductSku(params){
    return request({
        url: 'stock/getProductSku',
        method: 'post',
        data: params
    });
}

export function addStock(params){
    return request({
        url: 'stock/addStock',
        method: 'post',
        data: params
    });
}

export function getStockList(params){
    return request({
        url: 'stock/getStockList',
        method: 'post',
        data: params
    });
}

export function getStockDetail(params){
    return request({
        url: 'stock/getStockDetail',
        method: 'post',
        data: params
    });
}

export function updateStock(params){
    return request({
        url: 'stock/updateStock',
        method: 'post',
        data: params
    });
}

export function getStockLedgerList(params){
    return request({
        url: 'stock/getStockLedgerList',
        method: 'post',
        data: params
    });
}

export function isCheckStock(params){
    return request({
        url: 'stock/isCheckStock',
        method: 'post',
        data: params
    });
}

export function getStockSku(params){
    return request({
        url: 'stock/getStockSku',
        method: 'post',
        data: params
    });
}

