<template>
    <div class="card card-custom example example-compact">
        <div class="card-header">
            <h3 class="card-title">{{title}}</h3>
            <button type="button" class="close" @click="cancelForm()">
                <i aria-hidden="true" class="ki ki-close"></i>
            </button>
        </div>

        <!--begin::Form-->
        <form class="form fv-plugins-bootstrap fv-plugins-framework" ref="dataForm" id="dataForm">
            <div class="card-body" style="min-height:500px;max-height:500px;overflow:auto;">

                <div class="form-group row">
                    <label class="col-lg-5 col-form-label">盘点编号: {{data_form.check_number}}</label>
                    <label class="col-lg-4 col-form-label">仓库名称: {{data_form.warehouse_name}}</label>
                    <label class="col-lg-3 col-form-label">类型: {{getCheckType(data_form.type)}}</label>
                </div>

                <div class="form-group row align-items-center">
                    <div class="col-lg-12 col-xl-12">
                        <div class="row align-items-center">
                            <div class="col-md-4 my-2 my-md-0">
                                <div class="d-flex align-items-center">
                                    <label class="mr-3 mb-0 d-none d-md-block" style="width: 50px;">货架:</label>
                                    <b-form-select class="form-control" v-model="search_form.shelf_id" :options="shelf_list"  name="shelf_id">

                                    </b-form-select>
                                </div>
                            </div>
                            <a href="javascript:void(0);" class="btn btn-light-primary px-6 font-weight-bold" v-on:click="searchList()">Search</a>
                            <a href="javascript:void(0);" class="btn btn-light-primary px-6 font-weight-bold" style="margin-left:10px;" v-on:click="addCheckDetail()">增加</a>
                        </div>
                    </div>
                </div>

                <div class="form-group row" v-if="showAddView">
                    <label class="col-lg-2 col-form-label">产品 <span class="text-danger">*</span>:</label>
                    <div class="col-lg-10">
                        <treeselect
                                :load-options="loadOptions"
                                :default-expand-level="1"
                                :async="true"
                                placeholder="请选择产品"
                                v-model="data_form.product_id"
                        />
                        <input type="hidden" v-model="data_form.product_id" name="product_id"/>
                    </div>
                </div>
                <div class="form-group row" v-if="showAddView">
                    <label class="col-lg-2 col-form-label">SKU <span class="text-danger">*</span>:</label>
                    <div class="col-lg-10">
                        <treeselect
                                :multiple="true"
                                :options="sku_list"
                                :default-expand-level="1"
                                placeholder="请选择售卖属性"
                                v-model="data_form.sku_ids"
                        />
                        <input type="hidden" v-model="data_form.sku_ids" name="sku_ids"/>
                    </div>
                </div>

                <div class="form-group row">
                    <KTDatatable
                            v-bind:list="list"
                            v-bind:column="column"
                            v-bind:showSelect="false"
                            v-bind:showPagination="true"
                            v-bind:pageSize="page.limit"
                            v-bind:pageCount="page.pageCount"
                            v-bind:total="page.total"
                            @operateHandler="operateHandler"
                            @pagechangeHandler="pagechangeHandler"
                    ></KTDatatable>
                </div>
            </div>

            <div class="card-footer">
                <button ref="data_form_submit" v-if="showAddView" class="btn btn-primary mr-2" type="button" @click="submitForm()">保存</button>
                <button class="btn btn-secondary" type="reset" @click="cancelForm()">关闭</button>
            </div>
        </form>
        <!--end::Form-->
    </div>
</template>
<script>

    import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
    import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
    import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
    import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
    import KTUtil from "@/assets/js/components/util";
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import Treeselect from '@riophae/vue-treeselect'
    import KTDatatable from "@/view/content/tables/Datatable.vue";
    import {getProduct, getProductSku} from '@/api/stock';

    import {getCheckDetailList, deleteCheckDetail, addCheckDetail} from '@/api/checkwarehouse';
    import {getShelf} from '@/api/warehouse';

    export default {
        name: "stock_check_update",
        props: {
            title: String,
            dialog_data: Object,
        },
        created:function() {
            var _this = this;
            _this.searchList();


            getShelf({'warehouse_id': _this.data_form.warehouse_id}).then(function(res) {
                if(res && res.response) {
                    _this.shelf_list = KTUtil.listToSelectOptions(res.response, 'id', 'shelf_number');
                }
            });
        },
        components :{
            KTDatatable
        },
        watch: {
            'data_form.product_id': function(val){
                var _this = this;
                if(_this.data_form.product_id && _this.data_form.warehouse_id) {
                    getProductSku({
                        'warehouse_id': _this.data_form.warehouse_id,
                        'product_id': _this.data_form.product_id
                    }).then(function (res) {
                        if (res && res.response) {
                            var options = [];
                            res.response.forEach(function (value) {
                                options.push({
                                    'id': value.id,
                                    'label': value.sku
                                });
                            });
                            _this.sku_list = options;
                        }
                    });
                }
            }

        },
        data() {
            return {
                list: [],
                column: [{
                    field: 'product_title',
                    title: '产品',
                    width: 380,
                    formatter: function(row) {
                        if(row.image_url){
                            var html =
                                    '<div class="d-flex align-items-center">' +
                                        '<div class="symbol symbol-80 flex-shrink-0">' +
                                            '<div class="symbol-label" style="background-image:url('+ row.image_url +')"></div>' +
                                        '</div>' +
                                        '<div class="ml-2">' +
                                            '<div class="text-dark-75 font-weight-bold line-height">'+ row.product_title+'</div>' +
                                            '<div class="text-dark-65 font-weight-bold line-height">'+ row.sku+'</div>' +
                                            '<div class="text-dark-65 font-weight-bold line-height">'+ row.material_number+'</div>' +
                                            '<div class="line-height">';
                                            var attr = JSON.parse(row.attr_info);
                                            for(var key in attr){
                                                html += '<span class="label label-primary label-inline font-weight-lighter mr-2" title="'+ key +'">'+ attr[key] +'</span>';
                                            }
                             html +=       '</div>'+
                                        '</div>' +
                                    '</div>';
                            return html;
                        } else {
                            return '';
                        }
                    }
                },{
                    field: 'shelf_number',
                    title: '货架'
                },{
                    field: 'operate',
                    title: '操作',
                    formatter: function(row){
                        let html = '' +
                            '<a href="javascript:;" class="btn btn-sm btn-clean btn-icon mr-2" title="删除">'+
                            '<i class="la la-trash-o icon-lg"></i>'+
                            '</a>';
                        return html;
                    }
                }],
                data_form:{
                    id: this.dialog_data.form_data.id,
                    warehouse_id: this.dialog_data.form_data.warehouse_id,
                    warehouse_name: this.dialog_data.form_data.warehouse_name,
                    check_number: this.dialog_data.form_data.check_number,
                    type: this.dialog_data.form_data.type,

                    product_id: null,
                    sku_ids: null,
                },
                page: {
                    currPage: 1,
                    limit: 15,
                    pageCount:0,
                    total:0,
                },
                search_form: {
                    product_title: '',
                    warehouse_id: '',
                    shelf_id: ''
                },
                shelf_list: [],
                sku_list: [],
                showAddView: false
            }
        },
        mounted() {

        },
        methods: {
            cancelForm(){
                this.$emit('opeareResultHandler', 1);
            },
            regionChange (data) {
                this.data_form.struct_address = data;
            },

            loadOptions({ action, searchQuery, callback }) {
                var _this = this;
                if (action === 'ASYNC_SEARCH') {
                    if(_this.data_form.warehouse_id) {
                        getProduct({'product_title': searchQuery, 'warehouse_id': _this.data_form.warehouse_id}).then(function (res) {
                            if (res && res.response) {
                                var options = [];
                                res.response.forEach(function (value) {
                                    options.push({
                                        'id': value.id,
                                        'label': value.site_name + '  ' + value.product_title
                                    });
                                });
                                callback(null, options);
                            }
                        });
                    }
                }

            },
            getCheckType(type) {
                if (type == 1) {
                    return '整仓盘库';
                } else if (type == 2) {
                    return '按货架盘点';
                } else if (type == 3) {
                    return '按产品盘点';
                }
            },
            operateHandler(clazz, row) {
                var _this = this;
                if(clazz.indexOf('la-trash-o') > -1){

                    //删除操作
                    KTUtil.confirm('操作提示', '是否确认删除该条记录？', function(){
                        deleteCheckDetail({id: row.id}).then(function (res) {
                            if (res) {
                                KTUtil.toast(_this, '结果提醒', '删除成功', 'success');
                                _this.searchList();
                            }
                        });
                    });
                }
            },
            pagechangeHandler(page, pageSize) {
                this.page.currPage = page;
                this.page.limit = pageSize;
                this.searchList();
            },
            searchList() {
                var _this = this;
                var data = _this.search_form;
                data['check_id'] = _this.data_form.id;
                data['page'] = _this.page.currPage;
                data['limit'] = _this.page.limit;
                getCheckDetailList(data).then(function(res) {
                    if(res && res.response) {
                        _this.list = res.response.list;
                        _this.page.total = res.response.total;
                        _this.page.pageCount = res.response.count;
                    }
                });
            },
            addCheckDetail() {
                this.showAddView = !this.showAddView;
                this.data_form.product_id = null;
                this.data_form.sku_ids = null;
            },
            submitForm() {

                const data_form = KTUtil.getById("dataForm");

                //表单数据验证
                this.fv = formValidation(data_form, {
                    fields: {
                        product_id: {
                            validators: {
                                notEmpty: {
                                    message: "请选择产品"
                                }
                            }
                        },
                        sku_ids: {
                            validators: {
                                notEmpty: {
                                    message: "请选择产品售卖属性"
                                }
                            }
                        },
                    },
                    plugins: {
                        trigger: new Trigger(),
                        submitButton: new SubmitButton(),
                        bootstrap: new Bootstrap()
                    }
                });
                this.fv.on("core.form.valid", () => {
                    var _this = this;

                    //按钮加载动画
                    const submitButton = this.$refs["data_form_submit"];
                    submitButton.classList.add("spinner", "spinner-light", "spinner-right");

                    addCheckDetail(_this.data_form).then(function (res){
                        if (res) {
                            KTUtil.toast(_this, '结果提醒', '保存成功', 'success');
                            data_form.reset();
                            _this.searchList();
                        }
                        submitButton.classList.remove(
                            "spinner",
                            "spinner-light",
                            "spinner-right"
                        );
                    });
                });
                this.fv.validate();
            }
        }
    }
</script>
